import React from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Grid, Tabs, TabPanel, Switch, Divider } from '_template';
import {
  CreateConsultantMutation,
  UpdateConsultantMutation,
} from 'modules/Consultant/query';
import { ConsultantData } from 'modules/Consultant/interfaces/consultant';
import { ConsultantSelect } from 'modules/Consultant/components';
import { PositionSelect } from 'modules/Position/components';
import { handleSaveData } from 'helpers/data/handleSaveData';
import PhoneField from 'components/PhoneField';
import TextField from 'components/TextField';
import { ROUTE } from 'constants/route';
import ConsultantPresentationList from './components/ConsultantPresentationList';
import ConsultantFilesList from './components/ConsultantFilesList';
import ConsultantEmailList from './components/ConsultantEmailList';

interface ConsultantFormProps {
  recordId?: string;
  data?: ConsultantData;
}

const ConsultantForm = ({ recordId, data }: ConsultantFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isNew = recordId === 'new';

  const [createMutation] = useMutation(CreateConsultantMutation);
  const [updateMutation] = useMutation(UpdateConsultantMutation);

  const onCancel = () => navigate(ROUTE.CONSULTANT_LIST);

  const validationSchema = Yup.object({
    parentId: Yup.string().required(t<string>(`CONSULTANT.PARENT_REQUIRED`)),
    positionId: Yup.string().required(
      t<string>('CONSULTANT.POSITION_REQUIRED')
    ),
    firstName: Yup.string().required(
      t<string>(`CONSULTANT.FIRST_NAME_REQUIRED`)
    ),
    lastName: Yup.string().required(t<string>(`CONSULTANT.LAST_NAME_REQUIRED`)),
    email: Yup.string()
      .email(t<string>(`VALIDATION.EMAIL_NOT_VALID`))
      .required(t<string>(`CONSULTANT.EMAIL_REQUIRED`)),
  });

  const onSubmit = (newData: ConsultantData) => {
    const variables = {
      id: recordId,
      data: {
        parentId: newData.parentId,
        positionId: newData.positionId,
        currencyId: newData.currency,
        active: !!newData.active,
        firstName: newData.firstName,
        lastName: newData.lastName,
        titleBefore: newData.titleBefore,
        titleAfter: newData.titleAfter,
        mobilePrefix: newData.mobilePrefix,
        mobileNumber: newData.mobileNumber,
        email: newData.email,
        companyName: newData.companyName,
        companyIC: newData.companyIC,
        companyDIC: newData.companyDIC,
        companyICDPH: newData.companyICDPH,
        permanentAddress: newData.permanentAddress,
        bankIBAN: newData.bankIBAN,
        bankSWIFT: newData.bankSWIFT,
        canPresentations: newData.canPresentations,
        userName: newData.userName,
        bonatoNumber: newData.bonatoNumber,
      },
    };

    if (isNew) {
      (variables.data as any) = {
        ...variables.data,
        registrationDate: moment(newData.registrationDate).format('YYYY-MM-DD'),
        number: newData.number,
      };
    }

    handleSaveData(
      isNew ? createMutation : updateMutation,
      variables,
      onCancel
    );
  };

  return (
    <Formik
      initialValues={{
        ...data,
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} onCancel={onCancel}>
          <Tabs panelsToCard>
            <TabPanel label={t('GENERAL.BASIC_DATA')}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <ConsultantSelect
                    fullWidth
                    required
                    name="parentId"
                    label={t('CONSULTANT.PARENT')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <PositionSelect
                    fullWidth
                    name="positionId"
                    label={t('CONSULTANT.POSITION')}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    disabled
                    name="number"
                    label={t('CONSULTANT.NUMBER')}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    type="date"
                    fullWidth
                    disabled={!isNew}
                    name="registrationDate"
                    label={t('CONSULTANT.REGISTRATION_DATE')}
                  />
                </Grid>
              </Grid>

              <Divider sx={{ mb: 4 }} />

              <Grid container spacing={4}>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    name="titleBefore"
                    label={t('CONSULTANT.TITLE_BEFORE')}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    autoFocus
                    fullWidth
                    required
                    name="firstName"
                    label={t('CONSULTANT.FIRST_NAME')}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    name="lastName"
                    label={t('CONSULTANT.LAST_NAME')}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    name="titleAfter"
                    label={t('CONSULTANT.TITLE_AFTER')}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <PhoneField name="mobile" label={t(`CONSULTANT.MOBILE`)} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    type="email"
                    name="email"
                    label={t(`CONSULTANT.EMAIL`)}
                  />
                </Grid>
              </Grid>

              <Divider sx={{ mb: 4 }} />

              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="companyName"
                    label={t(`CONSULTANT.COMPANY_NAME`)}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    name="companyIC"
                    label={t(`CONSULTANT.COMPANY_IC`)}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    name="companyDIC"
                    label={t(`CONSULTANT.COMPANY_DIC`)}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    name="companyICDPH"
                    label={t(`CONSULTANT.COMPANY_ICDPH`)}
                  />
                </Grid>
              </Grid>

              {/* <Grid container>
                <Grid item xs={12}>
                  <I18nextSelect
                    required
                    name="language"
                    label={t('GENERAL.BACKOFFICE_LOCALIZATION')}
                  />
                </Grid>
              </Grid> */}

              <Divider sx={{ mb: 4 }} />

              <Grid container spacing={4}>
                <Grid item xs={6} sm={3}>
                  <TextField name="userName" label={t(`CONSULTANT.USERNAME`)} />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField name="vs" label={t(`CONSULTANT.VS`)} disabled />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    name="bonatoNumber"
                    label={t(`CONSULTANT.BONATO_NUMBER`)}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={6}>
                  <Switch name="active" label={t('CONSULTANT.ACTIVE')} />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel label={t('CONSULTANT.PRESENTATIONS')}>
              <Grid container>
                <Grid item xs={6}>
                  <Switch
                    name="canPresentations"
                    label={t('CONSULTANT.CAN_PRESENTATIONS')}
                  />
                </Grid>
              </Grid>

              {recordId && (
                <ConsultantPresentationList consultantId={recordId} />
              )}
            </TabPanel>
            {recordId && (
              <TabPanel label={t('CONSULTANT.FILES')}>
                <ConsultantFilesList filesId={data?.files?.id} />
              </TabPanel>
            )}
            {recordId && (
              <TabPanel label={t('CONSULTANT.EMAILS')}>
                <ConsultantEmailList consultantId={recordId} />
              </TabPanel>
            )}
          </Tabs>
        </Form>
      )}
    </Formik>
  );
};

export default ConsultantForm;
